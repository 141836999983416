.selectContainer {
  display: none;
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: #31323b;
  z-index: 10000;
}

.selectContainer.active {
  display: flex;
  flex-direction: column;
}

.container {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: grid;
  align-items: center;
  justify-content: center;
  overflow: auto;
  padding: 1em 1em;
  flex-direction: column;
  position: relative;
}

.card {
  width: 100%;
  /* max-width: 28em; */
  background: #191a1d;
  border-radius: 43px;
  display: flex;
  align-items: center;
  padding: 2em;
  justify-content: flex-start;
  flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
  color: var(--default);
  animation: show 300ms alternate;
}

@keyframes show {
  from {
    transform: scale(0.95);
  }
  to {
    transform: scale(1);
  }
}

.Icon {
  background: #d9d9d9;
  height: 6rem;
  width: 6rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.title {
  font-weight: 700;
  font-size: 2rem;
  margin-top: 0.5em;
}

.text {
  font-weight: 400;
  font-size: 24px;
  padding-left: 1rem;
  margin-bottom: 2em;
}

.btn {
  background: #ff0000;
  border-radius: 67px;
  width: 100%;
  height: 2.5em;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  cursor: pointer;
}
.passInput {
  border: none;
  outline: none;
  background: transparent;
  width: 100%;
  border: 2px solid transparent;
  border-bottom: 2px solid white;
  padding: 1rem;
  color: white;
  transition: all 0.2s ease-in-out;
}
.inputWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.inputWrapper P {
  text-align: left;
  color: var(--main-color);
  margin-bottom: 0.6em;
}
.passInput::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(255, 255, 255);
  opacity: 1; /* Firefox */
}

.passInput:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgb(255, 252, 252);
}

.passInput::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgb(255, 255, 255);
}
.passInput:focus {
  border: 2px solid white;
  border-radius: 39.8818px;
}
@media (max-width: 480px) {
  .title {
    font-size: 1.5rem;
  }
  .text {
    font-size: 1rem;
  }
  .btn {
    font-size: 1rem;
  }
}
