.contianer {
  background: var(--dashborad-secondary-background);
  border-radius: 6.32666px;
  padding: 1em;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.contianer .header {
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
}

.contianer .header p {
  color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 1.125rem;
}
.contianer .header svg {
  margin-right: 0.5em;
  width: 1.625rem;
}
.contianer .header path:first-child {
  stroke: #ffb636;
}
.contianer .header path:last-child {
  fill: #ffb636;
  stroke: #ffb636;
}
.contianer .header .tabs {
  /* display: grid;
  grid-template-columns: repeat(3, 1fr); */
  display: flex;
  gap: 1em;
  font-size: 12px;
}
.contianer .header .tabs > div {
  padding: 0.5em 1em;
  text-align: center;
  background: #111315;
  border-radius: 5px;
  color: #535557;
  cursor: pointer;
}
.contianer .header .tabs > div:hover {
  background: var(--main-color);
  color: var(--default);
}
.contianer .header .tabs > div.active {
  background: var(--main-color);
  color: var(--default);
}
.contianer .header .viewAll {
  font-size: 0.75rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.sliderContainer {
  width: 100%;
}
.card {
  background: var(--dashborad-primary-background);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0px 0px 11.9889px rgba(0, 0, 0, 0.12);
  border-radius: 14.3867px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: min-content;
  overflow: hidden;
  min-height: 340px;
}

.content {
  padding: 0.4375rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.content > img {
  height: 200px;
  width: 200px;
  object-fit: cover;
  border-radius: 10px;
}
.content svg {
  height: 27px;
  width: 27px;
  padding: 1px;
  border-radius: 50%;
  background: var(--default);
  position: absolute;
  bottom: -5.5px;
  font-size: 9px;
  right: 2em;
}
.content .address {
  display: flex;
  position: absolute;
  background: var(--default);
  color: black;
  align-items: center;
  font-size: 9px;
  box-shadow: 0px 0px 11.9889px rgb(0 0 0 / 12%);
  border-radius: 14.3867px;
  width: 10em;
  justify-content: space-around;
  height: 3.3em;
  left: 2em;
  bottom: -5.5px;
  padding: 5px;
}
.content .address img {
  height: 100%;
  margin-right: 4px;
}

.title {
  font-weight: 700;
  width: calc(100% - 2em);
  margin: auto;
  margin-top: 1rem;
  text-align: left;
}

.line {
  background: rgba(255, 255, 255, 0.4);
  height: 2px;
  width: 100%;
  margin: 1rem 0 0rem;
}

.viewBtn {
  color: var(--main-color);
  font-size: 0.875rem;
  font-weight: 700;
  width: 100%;
  height: 100%;
  cursor: pointer;
  padding: 0.9em 0 0.9em;
  transition: all 0.2s ease-in-out;
}
.viewBtn:hover {
  background: var(--main-color);
  color: var(--default);
}

@media screen and (max-width: 460px) {
  .contianer .header p {
    font-size: 1rem;
  }
  .contianer .header {
    row-gap: 1rem;
    flex-wrap: wrap;
  }
  .contianer .header .tabs {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 400px) {
  .card {
    margin: auto;
  }
}
