.container {
  font-family: "Helvetica", arial;
  font-weight: 400;
  width: 100%;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-top: 7.5em;
  min-height: 100vh;
  overflow-y: hidden;
  background: var(--main-background-color);
}
.banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 3rem 0rem;
}
.banner > img {
  max-width: 500px;
  width: 100%;
}
.header {
  font-size: 4rem;
  line-height: 140%;
  font-weight: 700;
  margin: 0.8em 0;
}

.content {
  font-weight: 500;
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

.popupContainer {
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  display: none;
  background: rgba(0, 0, 0, 0.75);
  z-index: 10000;
}
.popupContainer.active {
  display: block;
}
.btnContainer {
  display: flex;
  justify-content: center;
  gap: 2rem;
  width: 100%;
  margin-top: 2rem;
}
.btn {
  padding: 1.1rem 2rem;
  font-weight: 700;
  border-radius: 35.1918px;
  text-align: center;
  width: 100%;
  max-width: 287px;
  background-color: var(--main-color);
  font-size: 1.125em;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  line-height: 160%;
  border: 1px solid transparent;
}
a.btn {
  color: var(--default);
  border-color: var(--default);
  background: transparent;
  text-decoration: underline;
}
.btn:hover {
  transform: scale(1.05);
  font-weight: 700;
}
@media only screen and (max-width: 900px) {
  .header {
    font-size: 2rem;
  }
  .content {
    font-size: 1rem;
  }
}

@media screen and (max-width: 480px) {
  .header {
    font-size: 1.75rem;
    width: 80%;
    margin: 2rem 0 1rem;
  }
  .content {
    font-size: 0.8125rem;
  }
  .btn {
    font-size: 0.75rem;
  }
  .banner img {
    max-width: 200px;
  }
  .btnContainer {
    gap: 1rem;
  }
}
