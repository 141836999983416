.container {
    font-family: "Helvetica", arial;
    font-weight: 400;
    width: 100%;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    padding-top: 7.5em;
    min-height: 100vh;
    overflow-y: hidden;
    background: var(--main-background-color);
    position: relative;
  }
  .section{
    display: flex;
    flex-flow: column nowrap;
    -ms-flex-flow: column nowrap;
  }
  .banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 3rem 0rem;
  }
  .bluntRequestIcon{
    max-width: 500px;
    width: 60%;
    margin-bottom: 1.6em;
  }
  .header {
    font-size: 4rem;
    font-weight: 700;
    margin: 0.8em 0;
    min-width: 200px;
    color: var(--default);
  }
  
  .content {
    font-weight: 400;
    line-height: 1.24;
    font-size: 0.9rem;
    margin-bottom: 1rem;
    color: var(--default);
  }

  .btnArea{
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    column-gap: 1rem;
  }
  
  .btn {
    padding: 1.2em 1em;
    font-weight: 700;
    border-radius: 50px;
    text-align: center;
    width: 12em;
    background-color: var(--main-color);
    font-size: 0.85em;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    margin: 1.2em 0;
    border: 1px solid var(--main-border);
    color: var(--default);
  }
  .btn:hover {
    transform: scale(1.05);
    font-weight: 700;
  }

  .btnReverse{
    border: 1px solid var(--outline);
    background: transparent;
    text-decoration: none;
  }
  
  .popupContainer {
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    display: none;
    background: rgba(0, 0, 0, 0.75);
    z-index: 10000;
  }
  .popupContainer.active {
    display: block;
  }
  
  .smookingStickDrawer{
    display: flex;
    flex-flow: column nowrap;
    -ms-flex-flow: column nowrap;
    width: 96vw;
    top:220px;
    position: absolute;
    z-index: 1;
    background: #24262E;
    bottom: 0;
    margin: 0 auto;
    transition: all 400ms;
    border-radius: 5.49793px;
    overflow: hidden;
    align-self: center;
    max-width: 900px;
  }
  .smokingStickHeader{
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    -ms-flex-flow: row nowrap;
    /* width: 100%; */
    justify-content: space-between;
    background: #0F1222;
    border-radius: 0px;
    margin: unset;
    /* border-radius: 5.49793px; */
  }
  .smookingStickTitleSection{
    align-self: flex-start;
    text-align: left;
  }
  .smookingStickTitleSection * {
    margin: unset;
  }
  .smookingStickTitleSection > h2{
    font-size: 20px;
  }
  .smookingStickTitleSection > p{
    font-size: 14px;
  }

  .listSmookingSticks{
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    gap: 18px;
    padding: 1.4em;
  }
  .smookingStick{
    display: flex;
    flex-flow: column nowrap;
    /* align-items: center; */
    justify-content: center;
    /* align-content: center; */
    width: 120px;
    height: 120px;
    padding: 40px 17px;
    border-radius: 5.49793px;
    background: #D9D9D9;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: 1px solid #D9D9D9;
  }

  .smookingStick:hover{
    border: 1px solid var(--main-color);

  }
  @media only screen and (max-width: 900px) {
    .header {
      font-size: 2rem;
    }
    .content {
      font-size: 1rem;
    }
  }
  
  @media screen and (max-width: 768px) {
    /* .header {
      font-size: 3rem;
      text-align: center;
    }
    .content {
      font-size: 2rem;
    } */
    /* .connect {
      padding: 1rem 5rem;
    } */
  }
  
  @media screen and (max-width: 480px) {
    .header {
      font-size: 1.7rem;
    }
  }
  