.container {
  padding: 2rem 1rem 5em;
  display: flex;
  flex-direction: column;
  color: var(--default);
}
.goBack {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
}
.goBack p {
  font-size: 1.5rem;
  font-weight: 700;
}
.goBack svg {
  margin-right: 1em;
  transition: all 0.2s ease-in;
}
.goBack:hover svg {
  transform: translateX(-8px);
}
.grid {
  display: grid;
  grid-template-columns: calc(47% - 3rem) 53%;
  gap: 3rem;
  margin-top: 2rem;
}
.grid .nftImg {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 16px;
}
.grid .nftImg img {
  background: #36434e;
  border-radius: 32px 32px 0 0;
  object-fit: cover;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  background-size: cover;
}
.viewFillsScreen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.viewBtn {
  padding: 1.5rem 0;
  background: #36434e;
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 0px 0px 16px 16px;
}
.viewFillsScreen:hover .viewBtn {
  border-radius: 0px;
}
.viewBtn p {
  font-size: 1.25rem;
  font-weight: 700;
}
.viewBtn svg {
  margin-right: 8px;
}
.viewHover {
  width: 100%;
  height: 2rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../../assets/imgs/triangle.svg");
  background-position-y: -2rem;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
}
.viewFillsScreen:hover .viewHover {
  background-position-y: 0rem;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.title {
  font-size: 2rem;
  font-weight: 700;
}
.bluntdao {
  margin-top: 1rem;
  display: flex;
  align-items: center;
}
.bluntdao img {
  margin-right: 1rem;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.bluntdao p {
  font-size: 1.25rem;
}
.label {
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  font-weight: 700;
}
.createdBy {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 1rem;
}
.address {
  display: flex;
  background: var(--default);
  color: black;
  align-items: center;
  font-size: 12px;
  box-shadow: 0px 0px 11.9889px rgb(0 0 0 / 12%);
  border-radius: 14px;
  width: 10em;
  justify-content: space-around;
  height: 2.3em;
  padding: 1px 5px;
  margin-right: 2rem;
  overflow: hidden;
}
.address div {
  height: 16px;
  width: 16px;
  margin-right: 4px;
  border-radius: 50%;
}
.mintDate {
  display: flex;
  align-items: center;
  width: 100%;
}
.mintDate svg {
  margin-left: 0.8rem;
  cursor: pointer;
}
.mintDate svg:hover path {
  stroke: var(--main-color);
}

.line {
  height: 1px;
  width: 100%;
  background: #d9d9d9;
  margin-bottom: 0.5rem;
}
.description {
  font-weight: 300;
  font-size: 0.875rem;
  letter-spacing: 0.5px;
  text-align: left;
  line-height: 1.4em;
}
.metaData {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 1rem;
  column-gap: 1rem;
  width: 100%;
}
.metaData > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background: #36434e;
  border-radius: 11px;
  gap: 0.5rem;
}
.metaData > div p {
  font-weight: 700;
  font-size: 15px;
  color: #ff9783;
}
.metaData > div div {
  font-weight: 700;
  font-size: 13px;
}
/* NFT popup container */
.popupContainer {
  display: none;
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.75);
  z-index: 10000;
}

.popupContainer.active {
  display: flex;
  flex-direction: column;
}

.wrapper {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  overflow: auto;
  padding: 1em 1em;
  flex-direction: column;
  position: relative;
}
.wrapper img {
  animation: show 300ms alternate;
  height: 94%;
  width: 100%;
  max-width: fit-content;
  object-fit: contain;
}
.wrapper svg {
  cursor: pointer;
  height: 6%;
  margin: 0.2em 0 auto auto;
}
@keyframes show {
  from {
    transform: scale(0.95);
  }
  to {
    transform: scale(1);
  }
}
@media only screen and (max-width: 860px) {
  .metaData {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 730px) {
  .grid {
    grid-template-columns: 100%;
  }
  .nftImg {
    /* max-height: 400px; */
  }
}
@media only screen and (max-width: 480px) {
  .wrapper svg {
    margin: 0.2em 0 0 auto;
  }
}
