.priceDropdown {
  z-index: 1 !important;
  /* color: var(--main-color); */
}

.priceDropdown {
  position: relative;
  width: 12em;
  font-size: 10px;
  background: var(--dashborad-secondary-background);
  z-index: 10;
}

.priceDropdown span {
  font-size: 0.85rem;
}

.selectedPrice {
  cursor: pointer;
  background: var(--dashborad-secondary-background);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 24px;
  padding: 0.5em 1em;
  z-index: 99;
  transition: all 300ms;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: capitalize;
  gap: 0.5em;
}

.selectedPrice span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
}

.selectedPrice:hover {
  background: var(--dashborad-primary-background);
}

.selectedPrice {
  color: #747474;
}

.priceDropdown .dropdown {
  width: 100%;
  left: 0;
  position: absolute;
  background: var(--dashborad-secondary-background);
  color: #747474;
  visibility: hidden;
  opacity: 0;
  transition: all 250ms;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transform: translateY(-0.5em);
  text-transform: capitalize;
}

.priceDropdown .dropdown.active {
  visibility: visible;
  opacity: 1;
  margin-top: 0.25em;
  transform: translateY(0em);
}

.priceDropdown .dropdown > div {
  padding: 0.5em 1em;
  border: 1px solid #747474;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1em;
}

.priceDropdown .priceInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
  font-size: 0.55rem;
  color: var(--default);
}

.priceDropdown .dropdown > div:hover {
  background: var(--dashborad-primary-background);
}

.dropdownIcon {
  transition: all 250ms;
}

.dropdownIcon.active {
  transform: rotate(180deg);
}
