.container {
  display: flex;
  width: 100%;
  padding-top: 130px;
}
.container > div {
  width: 100%;
  background: rgb(245, 240, 234);
  overflow: hidden;
  min-height: 650px;
  height: 100% !important;
  display: grid;
}
.container > iframe {
  background: #f5f0ea;
  height: 100vh;
  width: 100%;
  max-width: 2000px;
  margin: auto;
}
