.container {
  border-radius: 6.32666px;
  padding: 2em 1em;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: var(--default);
  background: var(--dashborad-secondary-background);
}
.container .header {
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.tableItemWrapper > div {
  display: flex;
  align-items: center;
  padding: 1em;
  justify-content: center;
}

.container .header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1em;
  flex-wrap: wrap;
  row-gap: 1rem;
}
.container .header p {
  color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 1.125rem;
}

.container .header svg {
  margin-right: 0.5em;
  width: 1.625rem;
}

.container .tableHeader {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  width: 100%;
  margin-bottom: 0.5em;
  font-size: 10px;
}
.container .tableHeader {
  grid-template-columns: repeat(7, 1fr);
}
.container .tableHeader > div {
  background: var(--dashborad-primary-background);
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 700;
}

.container .tableHeader .controller {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
}
.container .tableHeader .controller svg {
  width: 9px;
  height: 100%;
  cursor: pointer;
}
.container .tableHeader .controller svg:hover path {
  fill: var(--main-color);
}
.container .tableHeader .controller svg.active path {
  fill: var(--main-color);
  opacity: 1;
}
.container .tableHeader .controller svg:first-child {
  margin-bottom: 2px;
}

.container .tableItemWrapper {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  width: 100%;
  font-size: 10px;
  background: var(--dashborad-primary-background);
  margin: 0.5em 0;
  transition: all 0.2s ease-in-out;
}
.container .tableItemWrapper {
  grid-template-columns: repeat(7, 1fr);
}
.tableItemWrapper:hover {
  transform: scale(1.01);
}
.tableItemWrapper > div > img {
  width: 15px;
  height: 15px;
  margin-right: 4px;
  border-radius: 50%;
}
.container .header .tabs {
  display: flex;
  gap: 1em;
  font-size: 12px;
}
.container .header .tabs > div {
  padding: 0.5em 1em;
  text-align: center;
  background: #111315;
  border-radius: 5px;
  color: #535557;
  cursor: pointer;
}
.container .header .tabs > div:hover {
  background: var(--main-color);
  color: var(--default);
}
.container .header .tabs > div.active {
  background: var(--main-color);
  color: var(--default);
}
.container .header .viewAll,
.feedContainer .header .viewAll {
  font-size: 0.75rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.feedContainer .header .viewAll {
  font-size: 1.125rem;
}
.container .header .viewAll:hover,
.feedContainer .header .viewAll:hover {
  color: var(--main-color);
}

.grid {
  display: grid;
  grid-template-columns: 65% calc(35% - 1rem);
  gap: 1rem;
}

.container .cardsWrapper {
  display: grid;
  gap: 0.5em;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  margin-bottom: 1rem;
}
.container .cardsWrapper > div {
  border: 0.85805px solid #36434e;
  border-radius: 2.57415px;
  padding: 1em 0.5em;
  background: var(--dashborad-primary-background);
}

.container .cardsWrapper > div .content {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  position: relative;
}
.container .cardsWrapper > div .content .icon {
  height: 37px;
  margin-right: 4px;
  position: relative;
}
.container .cardsWrapper > div .content .icon img {
  height: 100%;
  border-radius: 50%;
}
.container .cardsWrapper > div .content .icon svg {
  position: absolute;
  width: 1em;
  height: 1em;
  bottom: 0.4em;
  right: -0.4em;
}
.container .cardsWrapper > div .content .text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 8px;
  width: 100%;
}
.container .cardsWrapper > div .content .text div {
  width: 100%;
  font-size: 0.6875rem;
  font-weight: 700;
  display: flex;
  align-items: center;
}
.container .cardsWrapper > div .content .text p {
  font-size: 0.5rem;
  margin-top: 0.4em;
}

.container .cardsWrapper > div .line {
  background: rgba(255, 255, 255, 0.2);
  height: 1px;
  width: 100%;
  margin: 0.75rem 0;
}
.container .cardsWrapper > div .cardBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.container .cardsWrapper > div .cardBottom .chapter {
  font-size: 0.625rem;
  display: flex;
  align-items: center;
}
.container .cardsWrapper > div .cardBottom .onboraded {
  font-weight: 700;
  font-size: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f2f2f2;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 15.7448px;
  padding: 1em;
}

.container .cardsWrapper > div .content .cardIndex {
  font-weight: 700;
  font-size: 5rem;
  color: rgba(255, 255, 255, 0.14);
  position: absolute;
  left: -7px;
  top: -40px;
}

.tableItemPrg {
  display: flex;
  align-items: center;
  font-size: 0.5625rem;
  color: #00cc9b;
  background: rgba(0, 204, 155, 0.12);
  border: 1px solid #00cc9b;
  border-radius: 3px;
  padding: 0.6em 1em;
}
.tabWrapper {
  display: grid;
  overflow-x: auto;
  width: 100%;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.tabWrapper::-webkit-scrollbar {
  display: none;
}
.solanaIcon {
  max-height: 1rem;
  width: fit-content;
}
.copyIcon {
  height: 1rem;
  width: auto;
  margin-left: 0.6em;
  margin-right: auto;
  cursor: pointer;
}
.copyIcon:hover path {
  stroke: var(--main-color);
}
/* AI train Model */
.AItrainContainer {
  background: #0f2d54;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  justify-content: center;
}

.btn {
  background: var(--main-color);
  border-radius: 30px;
  color: #ffffff;
  font-size: 0.875rem;
  width: 12em;
  height: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
}
.btn:hover {
  transform: scale(1.05);
}
.AItrainContainer .title {
  font-weight: 700;
  font-size: 1.125rem;
}
.AItrainContainer .text {
  font-size: 0.875rem;
  margin: 1em 0;
}
@media screen and (max-width: 830px) {
  .grid {
    grid-template-columns: 100%;
  }
}
@media screen and (max-width: 780px) {
  .cardCotnainer .cardWrapper .card {
    flex-direction: column;
    justify-content: space-between;
  }
  .cardCotnainer .cardWrapper .card > svg {
    height: 100%;
  }
  .cardCotnainer .cardWrapper .card .titleCard div {
    font-size: 1.5rem;
    text-align: center;
  }
  .cardCotnainer .cardWrapper .card .titleCard p {
    font-size: 0.875rem;
  }
}
@media screen and (max-width: 1230px) {
  .container .cardsWrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 830px) {
  .grid {
    grid-template-columns: 100%;
  }
}
@media screen and (max-width: 780px) {
  .cardWrapper .card {
    flex-direction: column;
    justify-content: space-between;
  }
  .cardWrapper .card > svg {
    height: 100%;
  }
  .cardWrapper .card .titleCard div {
    font-size: 1.5rem;
    text-align: center;
  }
  .cardWrapper .card .titleCard p {
    font-size: 0.875rem;
  }
}
@media screen and (max-width: 460px) {
  .cardWrapper {
    grid-template-columns: repeat(1, 1fr);
  }
  .cardWrapper .card {
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .cardWrapper .card > svg {
    margin: auto;
  }
  .cardWrapper .card .titleCard {
    margin: auto;
  }
  .container > .header {
    font-size: 1rem;
  }
  .container .header p {
    font-size: 1rem;
  }

  .container .cardsWrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}
